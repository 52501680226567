const ArticleHeader = () => import('./ArticleHeader');
const ArticlesWebContent = () => import('./ArticlesWebContent');
const ArticleCollapseWebContent = () => import('./ArticleCollapseWebContent');

export {ArticleHeader as _ArticleHeader};
export {ArticlesWebContent as _ArticlesWebContent};
export {ArticleCollapseWebContent as _ArticleCollapseWebContent};

export * from './ArticleOurShops';
export * from './ArticlePaymentMethods';
export * from './ArticlePrivacidad';
export * from './ArticleReturnsAndExchanges';
export * from './ArticleServices';
export * from './ArticleTerminosYCondiciones';
export * from './ArticleTuOpnion';
export * from './ArticleWithdrawalConditions';
export * from './WhoAreWe';
export * from './ArticleSustainability';
